
.claimButton {
 color: #b4a770;
  border-color: #f0c435;
  height: 50px; 
  width: 100%;
  border-width: 2px;
  text-transform: none; 
  background-color: #f0c435;
}



.nftGridContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1%;
  width: 100%;
}

/*
// this has been moved in theme.js
.nftContainer {
   width: 17.8%;  
  margin: 8px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  backface-visibility: hidden;
  outline: 1px solid rgba(255, 255, 255, 0.1);
  transform: translate3d(0px, 0px, 0px);
  display: flex;
  flex-direction: column;
  padding: 8px;
  max-height: 382px;
  transition: all 0.25s ease-in-out;
}
*/

.nftContainer:hover {
  cursor: pointer;
  transform: translate3d(0px, -4px, 0px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
 

  .characterBox {
  position: relative;
  top: 0;

  width: 512px;
  height: 512px;

   width: '100%';
   height: '100%';

}  
  
 
/* xs: Extra small (up to 599px width)
sm: Small (600px to 959px width)
md: Medium (960px to 1279px width)
lg: Large (1280px and up width) */
/* Tablet and smaller laptops */
/* Mobile   Less than 600     767px  */
@media (max-width:600px ) { 
  

   .composedLayerPosition {
       position: absolute;       
       width: '100%';
       height: '100%';  

      
       /* background-color : #0000FF; */

    }
    .characterBox {
       top: 0;
       left:-20px;
       /* width:  '100%';
       height: '100%'; */
       
       width: 420px;
       height: 420px;
      


    }
    

    .RewardPrice{

      grid-column : span 4;
      grid-row : span 6; 
      padding-left :90px;
    }  


    
      .mainPageLayout {
        grid-column: span 12;
        grid-row: span 4;
      }
      .ImageSelector {

        grid-column: span 12;
        grid-row: span 4;
      }
}

   @media (min-width: 600px) and (max-width: 959px ) {   /*  760px */
     
  .Sidebar { display: block;   
   } 

   .composedLayerPosition {
    position: absolute;       
    width: '100%';
    height: '100%';  

    }
    .characterBox {
      top: -20px;
      left:50px;
        width:  '100%';
        height: '100%';  
    }




   .RewardPrice{

    grid-column : span 4;
    grid-row : span 6; 
    padding-left :90px;
  }  


 
  .mainPageLayout {
    grid-column: span 12;
    grid-row: span 4;
  }
  .ImageSelector {

    grid-column: span 12;
    grid-row: span 4;
  }


  


}
 

@media (min-width: 960px) and (max-width: 1279px) {

  .composedLayerPosition {
    position: absolute;       
    width: '100%';
    height: '100%';  

    }
    .characterBox {
      top: 0px;
      left: 0; /* 50px;  */
        width:  '100%';
        height: '100%';  
    }




  .RewardPrice{

    grid-column : span 4;
    grid-row : span 6; 
    padding-left :90px;
  }  
  
  .mainPageLayout {
    grid-column: span 12;
    grid-row: span 4;
  }
  .ImageSelector {

    grid-column: span 12;
    grid-row: span 4;
  }
}

/* Desktop monitors and larger laptops */
@media (min-width: 1280px) {

  .composedLayerPosition {
    position: absolute;       
    width: '100%';
    height: '100%';  

    }
    .characterBox {
      top: 0px;
      left: 0; /* 50px;  */
        width:  '100%';
        height: '100%';  
    }




  .RewardPrice{

    grid-column : span 4;
    grid-row : span 6; 
    padding-left :90px;
  }  
  
  .mainPageLayout {
    grid-column: span 12;
    grid-row: span 4;
  }
  .ImageSelector {

    grid-column: span 12;
    grid-row: span 4;
  }

}


  

/*

NFT container
===========================================================================================================
===========================================================================================================
===========================================================================================================
===========================================================================================================
*/



.title {
  padding: 8px;
  font-size: 3rem;
  margin-bottom: 8px;
}

/* Responsive */



@media (min-width: 760px) and (max-width: 1200px) {
  .nftContainer {
    width: 22%;
  }
}

@media (min-width: 600px) and (max-width: 760px) {
  .nftContainer {
    width: 29%;
  }
}

/* Less than 600 */
@media (max-width: 600px) {
  .nftContainer {
    width: 45%;
  }
}
@media (min-width: 1200px) {
}
