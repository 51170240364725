.container {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-top: 128px;
}

.metadataContainer {
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  vertical-align: inherit;
  display: flex;
  max-width: 100%;
}

.listingContainer {
  flex-shrink: 1;
  position: sticky;
  width: 100%;
  min-width: 370px;
  max-width: 450px;
  margin-top: 16px;
  margin-right: 16px;
}

.image {
  border-radius: 16px;
  width: 100% !important;
  height: auto !important;
  background-color: rgba(255, 255, 255, 0.04);
}

.input {
  display: block;
  width: 98%;
  padding: 12px 16px;
  margin-bottom: 0.5rem;
  background: transparent;
  border: none;
  font-size: 16px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 2px;
}

.contractMetadataContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}

.imageContainer {
  position: relative;
}

.crossButton {
  position: absolute;
  top: 10px;
  right: 10px;
  transition: background-color 0.2s ease-in-out;
}

.crossButton:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.collectionImage {
  width: 32px !important;
  height: 32px !important;
  border-radius: 8px;
  margin-right: 8px;
  margin-left: 16px;
  margin-right: 16px;
}

.collectionName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  margin: 0px;
  margin-left: 16px;
  margin-right: 16px;
}

.title {
  font-weight: 700;
  font-size: 32px;
  hyphens: auto;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  margin-left: 16px;
  margin-right: 16px;
}

.nftOwnerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 24px;
  gap: 8px;
  transition: opacity 0.2s ease-in-out;
  margin-left: 16px;
  margin-right: 16px;
}

.nftOwnerContainer:hover {
  cursor: pointer;
  opacity: 0.8;
}

.nftOwnerImage {
  margin-top: 16px;
  width: 48px;
  height: 48px;
  border-radius: 50%;

  opacity: 0.9;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.nftOwnerInfo {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 16px;
}

.label {
  color: rgba(255, 255, 255, 0.6);
  margin: 0px;
  margin-bottom: 4px;
}

.nftOwnerAddress {
  font-weight: 600;
  margin: 0px;
  color: rgba(255, 255, 255, 0.9);
}

.descriptionContainer {
  padding-left: 16px;
  padding-right: 16px;
}

.descriptionTitle {
  margin-top: 32px;
}

.traitsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 12px;
}

.traitContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px 12px;
  min-width: 128px;
  border-radius: 16px;
  min-height: 32px;
}

.eventsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 8px 12px;
  min-width: 128px;
  border-radius: 16px;
  min-height: 32px;
}

.traitValue {
  font-weight: 600;
  margin: 0px;
  color: rgba(255, 255, 255, 0.9);
}

.traitName {
  font-weight: 400;
  margin: 0px;
  color: rgba(255, 255, 255, 0.6);
}

.eventContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.txHashArrow {
  width: 24px;
  height: 24px;
  padding: 8px;
}

.pricingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  -webkit-box-flex: 1;
  flex-grow: 1;
  background: transparent;
  border-radius: 16px;
  overflow: hidden;
  margin-top: 32px;
  margin-bottom: 24px;
}

.pricingInfo {
  padding: 16px;
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.04);
  width: 100%;
  margin: 0px;
  margin-bottom: 12px;
}

.pricingValue {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  margin: 0px;
  border-radius: 8px;
}

.buyButton:hover {
  cursor: pointer;
  opacity: 0.8 !important;
}

.listingTimeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.listingTime {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  margin: 0px;
}

.or {
  margin-top: 16px;
  margin-bottom: 16px;
}

@media (max-width: 960px) {
  .container {
    flex-direction: column;
    padding-bottom: 128px;
  }

  .listingContainer {
    position: relative;
    width: 100%;
    min-width: 0px;
    max-width: 100%;
    top: 0px;
  }

  .metadataContainer {
    margin-top: 32px;
  }
}

.btn {
  /* fill remainin width */
  width: 100% !important;
}
