.title {
  padding: 8px;
  font-size: 3rem;
  margin-bottom: 8px;
}

.legend {
  color: rgba(255, 255, 255, 0.8);
  margin: 0px;
  margin-bottom: 8px;
}

.input {
  display: block;
  width: 98%;
  padding: 12px 16px;
  margin-bottom: 0.5rem;
  background: transparent;
  border: none;
  font-size: 16px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 2px;
}

.formSectionTitle {
  margin-top: 24px;
  margin-bottom: 8px;
}

.activeTabContent {
  display: flex;
  flex-direction: column;
  opacity: 1;
}
