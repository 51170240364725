@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
   font-family: "Source Sans Pro", sans-serif;

   margin-top: 26px; /*  this is suppose to be the height of the top bar , so content is not under the top bar*/
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  /* background: #e0e0e0;  way better with nothing*/
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
 
  