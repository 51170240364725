.nftImage {
  /* Override default NFT renderer width */
  width: 100% !important;
  
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);
  object-fit: cover;
}

.largeImage { /* used for*/
  /* Override default NFT renderer width */
  width: 100% !important;
  height: 100% !important;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);
  object-fit: cover;
}

.nftTokenId {
  font-size: 12px;
  margin: 0px;
  margin-top: 12px;
}

.nftName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  color: #fff;
  font-family: inherit;
  vertical-align: inherit;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
  margin: 0px;
  margin-bottom: 12px;
  margin-top: 4px;
}

.priceContainer {
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);
  min-height: 52px;
}

.nftPriceContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
}

.nftPriceLabel {
  margin: 0px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
}

.nftPriceValue {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-family: inherit;
  vertical-align: inherit;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
  border: 0px;
  color: #fff;
}
